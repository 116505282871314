import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import Button from "../Components/Shared/Btn/Button";
import InputField from "../Components/Shared/inputField/InputField";
import { useForm } from "react-hook-form";
import { Link, NavLink } from "react-router-dom";
import useRequest from "../Hooks/useRequest/useRequest";
import AlertToster from "../Components/Shared/Toastify/AlertToster";
import CryptoJS from "crypto-js";
import { useState } from "react";

const Register = () => {
  const reg = useRequest();
  const { register, handleSubmit } = useForm();

  const [checked, setChecked] = useState(true);
  const host = window.location.href.replace("/register", "");

  const onSubmit = (data) => {
    for (const property in data) {
      data[property] = CryptoJS.AES.encrypt(
        data[property],
        process.env.REACT_APP_SIGNUP_CREDENTIALS
      ).toString();
    }
    reg({ uri: "user", method: "POST", data })
      .then((res) => res.json())
      .then((data) => {
        data.error && AlertToster(data.error, "warning");
        if (data.newUser) {
          AlertToster("Success!", "success");
          window.location.replace(host + "/dashboard/subscription");
        }
      })
      .catch((err) => {
        data.error && AlertToster(data.error, "error");
        AlertToster("Permission denied!", "error");
      });
  };
  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="lg:w-2/5 md:w-2/3 w-2/3 space-y-8">
        <div className="mt-20">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <IoIosArrowRoundBack />
              <span className="uppercase">back to home</span>
            </div>
          </Link>

          <h1 className="text-gray-900 text-xl mt-2">
            <span className="font-bold">Register</span> below
          </h1>

          <div className="mt-2">
            <span className="text-bgLabelText">
              Already have an account?{" "}
              <Link to="/login" className="text-[#a88f90] font-bold">
                Log in
              </Link>
            </span>
          </div>
        </div>

        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label={"Name"}
            type="text"
            register={{ ...register("name"), required: true }}
          />
          <InputField
            label={"Email"}
            type="email"
            register={{ ...register("email"), required: true }}
          />
          <InputField
            label={"Password"}
            type="password"
            register={{ ...register("password"), required: true }}
          />
          <div className="form-control mt-3">
            <label className="label cursor-pointer">
              <input
                onClick={() => setChecked(!checked)}
                type="checkbox"
                className="checkbox"
              />
              <p className="ml-3">
                By clicking this box and creating an account, you acknowledge
                that you have read and agree to the{" "}
                <NavLink to="" style={{ textDecoration: "underline" }}>
                  Terms and Conditions{" "}
                </NavLink>
                of Happy Thumbs
              </p>
            </label>
          </div>
          <Button
            children={"sign up"}
            type="submit"
            className=""
            disabled={checked}
            customStyle={`${
              checked ? "bg-[#dfdfdf]" : "bg-bgbutton"
            } text-bgColor w-36 h-14 mt-8`}
          />
        </form>
      </div>
    </div>
  );
};

export default Register;
