import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useRequest from "../../../../Hooks/useRequest/useRequest";
import FilterEncData from "../../../../Utils/EncriptedData/FilterEncData";
import Button from "../../Btn/Button";
import InputField from "../../inputField/InputField";
import AlertToster from "../../Toastify/AlertToster";

const dataField = ["name", "email", "poshmarkUserName"];
const UserEditModal = ({ activeModal, setActiveModal }) => {
  const id = activeModal?.data?.id;
  const req = useRequest();
  const { register, setValue, getValues, handleSubmit } = useForm();

  useEffect(() => {
    Object.keys(activeModal?.data).forEach(
      (d) => dataField.includes(d) && setValue(d, activeModal?.data[d])
    );
  }, [activeModal?.data]);

  const defaultValue = {
    name: getValues("name"),
    email: getValues("email"),
    poshmarkUserName: getValues("poshmarkUserName"),
    proxyIp: getValues("proxyIp"),
  };

  const onSubmit = (data) => {
    if (JSON.stringify(defaultValue) !== JSON.stringify(data)) {
      // FilterEncData is function that return input touch value with encripted data
      let cData = FilterEncData(data, process.env.REACT_APP_UPDATE_DATA);
      req({
        uri: `user/update/${id}`,
        method: "PATCH",
        data: cData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.user) {
            AlertToster("User Updated", "success");
            setActiveModal({ active: false, data: {} });
          } else {
            AlertToster("Something went error", "error");
          }
        })
        .catch((e) => AlertToster("Bad request", "error"));
    } else {
      AlertToster("You should have to changeg something", "warning");
    }
  }

  return (
    <>
      <div
        id="crypto-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={`bg-[#ffffffc7] w-full
  h-full  absolute top-0 left-0 right-0 
  flex justify-center items-center `}
      >
        <div className="flex items-center justify-center w-full px-[20%] ">
          <div className="w-full bg-[#14141411] sm:p-2 rounded-[15px] shadow-2xl">
            <div className="relative bg-bgColor rounded-[15px] min-h-[20.0rem] p-10">
              <div className="pt-[16px] pb-[19px] mx-[11px]  min-h-[20.0rem] flex flex-col justify-between  ">
                <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <InputField
                      label="Name"
                      type="text"
                      customStyle="h-12"
                      register={{ ...register("name"), required: true }}
                    />
                  </div>
                  <div>
                    <InputField
                      label="Email"
                      type="email"
                      disable={true}
                      customStyle="h-12"
                      register={{ ...register("email"), required: true }}
                    />
                  </div>
                  <div>
                    <InputField
                      label="Poshmark Username"
                      type="text"
                      customStyle="h-12"
                      register={{
                        ...register("poshmarkUserName"),
                        required: false,
                      }}
                    />
                  </div>
                  <div>
                    <InputField
                      label="Proxy"
                      type="text"
                      customStyle="h-12"
                      register={{ ...register("proxyIp"), required: false }}
                    />
                  </div>
                  <div className="flex justify-between">
                    <Button
                      children="Update"
                      type="submit"
                      customStyle="bg-bgSaveTaskButton text-white w-32 h-10"
                    />
                    <Button
                      children="Close"
                      onClick={() =>
                        setActiveModal({ ...activeModal, active: false })
                      }
                      customStyle="bg-bgCloseButton text-white px-5 w-32 h-10"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEditModal;
