import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useRequest from "../../../../Hooks/useRequest/useRequest";
import getTitle from "../../../../Utils/FilterString/getTitle";
import Button from "../../Btn/Button";
import Dropdown from "../../Dropdown/Dropdown";
import AlertToster from "../../Toastify/AlertToster";
import selectedItem from "./TaskComponent/SelectItems";
import TaskInfo from "./TaskComponent/TaskInfo";

const Options = [
  "Offer To Likers",
  "Follow",
  "Share My Closet",
  "Share Other Closets",
  "New Posher Love",
  "Clear Out Closet",
];
const ShippindPaidOption = ["Free", "$4.99", "$5.24"];

const TaskModal = ({ showModal, setShowMadal }) => {
  const { register, handleSubmit, reset } = useForm();

  const [optSelect, setOptSelect] = useState("Offer To Likers");
  const [shippingPaid, setShippingPaid] = useState("Free");
  const reqTask = useRequest();

  // Delated indivital task and close the modal
  const onDeleteTask = (title) => {
    if (title) {
      let id = getTitle(title, 1);
      reqTask({ uri: `task/delete/${id}`, method: "DELETE" })
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            AlertToster("Task Deleted", "success");
            setShowMadal((prevState) => {
              let uValue = Object.assign({}, prevState); // creating copy of state variable showModalValue
              uValue.show = false;
              uValue.currentValue = {}; // update the name property, assign a new value
              return { uValue }; // return new object showModalValue object
            });
          }
        });
    } else {
      setShowMadal((prevState) => {
        let uValue = Object.assign({}, prevState); // creating copy of state variable showModalValue
        uValue.show = false;
        uValue.currentValue = {}; // update the name property, assign a new value
        return { uValue }; // return new object showModalValue object
      });
    }
  };
  // updated task
  const onUpdateTask = (title, newData) => {
    let id = getTitle(title, 1);
    reqTask({ uri: `task/${id}`, method: "PATCH", data: newData })
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          AlertToster("Task Updated", "success");
          setShowMadal((prevState) => {
            let uValue = Object.assign({}, prevState); // creating copy of state variable showModalValue
            uValue.show = false;
            uValue.currentValue = {}; // update the name property, assign a new value
            return { uValue }; // return new object showModalValue object
          });
        } else {
          AlertToster(data.error, "warning");
        }
      });
  };

  // Create new Task
  const onSubmit = (data, e) => {
    let paid = shippingPaid.toLowerCase();

    let selectValue =
      optSelect.replace(/\s/g, "").charAt(0).toLowerCase() +
      optSelect.replace(/\s/g, "").slice(1);

    let newData;
    if (optSelect === "Offer To Likers" || optSelect === "Clear Out Closet") {
      paid === "free"
        ? (newData = {
            actionName: selectValue,
            discountPercentage: parseInt(data.discountPercentage),
            shippingPaid: 0,
            days: [parseInt(showModal.currentValue.day)],
            hour: showModal.currentValue.time,
          })
        : (newData = {
            actionName: selectValue,
            discountPercentage: parseInt(data.discountPercentage),
            shippingPaid:
              parseFloat(shippingPaid.replace("$", "")) === 4.99 ? 1 : 2,
            minimumShippingPaidValue: data.minimumShippingPaidValue,
            days: [parseInt(showModal.currentValue.day)],
            hour: showModal.currentValue.time,
          });
    } else if (optSelect === "Share My Closet") {
      newData = {
        actionName: selectValue,
        days: [parseInt(showModal.currentValue.day)],
        hour: showModal.currentValue.time,
        topToBottom: data.topToBottom,
      };
    } else {
      newData = {
        actionName: selectValue,
        days: [parseInt(showModal.currentValue.day)],
        hour: showModal.currentValue.time,
      };
    }

    try {
      let title = showModal.currentValue.title;

      if (title) {
        // Uploaded function
        onUpdateTask(title, newData);
      } else {
        // Requested method
        reqTask({ uri: "task", method: "POST", data: newData })
          .then((res) => res.json())
          .then((data) => {
            data.status === 400 && AlertToster(data.reason, "warning");
            if (data.status === 200) {
              if (title) {
                AlertToster("Task Updated", "success");
              } else {
                AlertToster("Task Added", "success");
              }
              setShowMadal({
                ...showModal,
                show: !showModal.show,
                currentValue: {},
              });
            }
          });
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  return (
    <>
      <div
        id="crypto-modal"
        tabIndex="-1"
        aria-hidden="true"
        className={`bg-[#ffffffc7] w-full
        h-full  fixed top-0  left-0
        flex justify-center items-center `}
      >
        <div
          className="flex items-center justify-center
  relative sm:p-4 md:w-[600px] sm:w-[500px] w-[300px]"
        >
          <div className="w-full bg-[#14141411] sm:p-2 rounded-[15px] shadow-2xl">
            <div
              className="relative bg-bgColor 
            rounded-[15px] min-h-[20.0rem] p-10"
            >
              <div
                className="pt-[16px] pb-[19px] mx-[11px] 
               min-h-[20.0rem]  "
              >
                {showModal.currentValue.title && (
                  /** Task information of components ,it;s visible when title exist */
                  <TaskInfo
                    title={showModal.currentValue.title}
                    dayName={showModal.currentValue.dayName}
                    time={showModal.currentValue.timeValue}
                    onClose={() => onDeleteTask()}
                  />
                )}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className=" min-h-[20rem] flex flex-col justify-between "
                >
                  <div className="flex flex-col space-y-4">
                    <Dropdown
                      options={Options}
                      onChange={(e) => {
                        setOptSelect(e);
                        reset();
                      }}
                      label="Offer To Likers"
                    />
                    {/* Task Modal components by TaskComponent*/}
                    {selectedItem[optSelect](
                      ShippindPaidOption,
                      setShippingPaid,
                      register,
                      shippingPaid
                    )}
                  </div>
                  <div className="flex justify-between mt-5 ">
                    <Button
                      type="button"
                      children={
                        showModal.currentValue.title ? "Delete" : "Close"
                      }
                      onClick={(e) =>
                        onDeleteTask(showModal.currentValue.title)
                      }
                      customStyle={"bg-bgCloseButton w-32 h-10 text-white"}
                    />
                    <Button
                      type={"submit"}
                      children={
                        showModal.currentValue.title ? "Updated" : "Save Task"
                      }
                      customStyle={"w-32 h-10 bg-bgSaveTaskButton text-white"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TaskModal;
