import React from "react";
import { useNavigate } from "react-router-dom";
import AdminSubscriptionCards from "../../../Components/AdminSubscription/AdminSubscriptionCards";
import Button from "../../../Components/Shared/Btn/Button";
import useNavValue from "../../../Contexts/NavProvider/useNavValue";

const AdminSubscriptionList = () => {
  const navigate = useNavigate();
  const activeNav = useNavValue();

  return (
    <div className="mb-10 mt-10 grid  grid-cols-1 mx-5">
      <h2 className="text-xl xl:text-3xl text-bgCardHeader">Subscription</h2>
      <h4 className="text-base  xl:text-xl mt-4 text-bgCardHeader ">
        Dashboard/Parks
      </h4>

      <Button
        children="Create Subscription"
        onClick={function () {
          activeNav[1]("/dashboard/subscription");
          navigate("/dashboard/createsub");
        }}
        customStyle="w-32 md:h-14 xl:w-60 bg-bgbutton 
          text-white mt-8 mb-16 text-sm  xl:p-3 xl:text-base "
      />

      <AdminSubscriptionCards />
    </div>
  );
};

export default AdminSubscriptionList;
