import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import { CgUserList } from "react-icons/cg";
import { MdOutlineUnsubscribe } from "react-icons/md";
import { BiTask } from "react-icons/bi";
import useRequest from "../../Hooks/useRequest/useRequest";
import useUser from "../../Contexts/UserProvider/useUser";
import { useEffect } from "react";
import { useState } from "react";
import { remove } from "../arrayManage";

const RouteObject = () => {
  const logout = useRequest();
  const {
    user: [thisUser, setThisUser],
  } = useUser();

  const adminItems = [
    {
      id: 1,
      name: "Profile",
      icon: BsFillPersonFill,
      path: "/dashboard",
    },

    {
      id: 2,
      name: "Subscription",
      icon: MdOutlineUnsubscribe,
      path: "/dashboard/subscription",
      root: "adminsub",
    },

    {
      id: 5,
      name: "User List",
      icon: CgUserList,
      path: "/dashboard/userlist",
      root: "userlist",
    },
    {
      id: 6,
      name: "Logout",
      icon: IoIosLogOut,
      path: "/",
      component: <></>,
      action: () => {
        logout({ uri: "user/logout", method: "POST" })
          .then(() => setThisUser(null))
          .catch((err) => console.log(err));
      },
    },
  ];

  const userItems = [
    {
      id: 1,
      name: "Profile",
      icon: BsFillPersonFill,
      path: "/dashboard",
    },
    {
      id: 2,
      name: "Task Schedule",
      icon: BiTask,
      path: "/dashboard/taskshedule",
    },
    {
      id: 3,
      name: "Subscription",
      icon: MdOutlineUnsubscribe,
      path: "/dashboard/subscription",
    },
    {
      id: 4,
      name: "Logout",
      icon: IoIosLogOut,
      path: "/",
      component: <></>,
      action: () => {
        logout({ uri: "user/logout", method: "POST" })
          .then(() => setThisUser(null))
          .catch((err) => console.log(err));
      },
    },
  ];
  const [menuList, setMenuList] = useState(
    thisUser?.isAdmin ? adminItems : userItems
  );

  // useEffect(() => {
  //   if (thisUser?.boughtPackages && !thisUser?.isAdmin) {
  //     remove(setMenuList, 3);
  //   } else if (!thisUser?.boughtPackages && !thisUser?.isAdmin) {
  //     setMenuList(userItems);
  //   }
  // }, [thisUser]);

  return {
    menuList: menuList,
  };
};

export default RouteObject;
