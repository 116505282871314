import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../Contexts/UserProvider/useUser";
import useRequest from "../../Hooks/useRequest/useRequest";
import Button from "../Shared/Btn/Button";
import PoshmarkPopup from "../Shared/Modals/Poshmark/PoshmarkPopup";
import AlertToster from "../Shared/Toastify/AlertToster";

const PoshmarkLink = ({ id }) => {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const req = useRequest()
  // User <context
  const {
    user: [user, setUser],
  } = useUser();
  const { poshmarkPassword, poshmarkUserName } = user;

  const hanldeLinkButton = () => {
    if (poshmarkUserName && poshmarkPassword) {
      setOpen(open)

      req({
        uri: `user/update/${id}`,
        method: "PATCH",
        data: {
          poshmarkPassword: " ",
          poshmarkUserName: " "
        },
      })
        .then(res => res.json())
        .then(data => {
          if (data.user) {
            AlertToster("Poshmark deleted", "success");
            setUser(data.user)
          }
          else {
            AlertToster("Something went error", "error");
          }
        })
        .catch((e) => AlertToster("Bad request", "error"));

    }
    else {
      setOpen(!open)
    }
  }
  return (
    <>
      <div
        style={{ boxShadow: "3px 3px 20px 3px  lightgray" }}
        className=" p-5 text-center mt-10 "
      >
        <p className="pt-5  pb-3">Click here to link your Poshmark account.</p>

        <Button
          onClick={hanldeLinkButton}
          children={user?.poshmarkUserName && user?.poshmarkPassword ? "UnLink" : "Link"}
          customStyle="bg-bgbutton w-[75px] h-[36px] text-white"
        />
      </div>
      {open && <PoshmarkPopup id={id} setOpen={setOpen} setUser={setUser} />}
    </>
  );
};

export default PoshmarkLink;
