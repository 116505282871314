import React from "react";
import useUser from "../../Contexts/UserProvider/useUser";
import NotFound from "../NotFound/NotFound";
import Userlist from "./UList/Userlist";

export default function ListWrapper() {
  const {
    user: [user],
  } = useUser();

  return !user?.isAdmin ? <NotFound /> : <Userlist />;
}
