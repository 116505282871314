import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useNavValue from "../../Contexts/NavProvider/useNavValue";
import useUser from "../../Contexts/UserProvider/useUser";
import NotFound from "../NotFound/NotFound";
import CreateSubscription from "./Creating/CreateSubscription";

export default function CreateWrapper() {
  const {
    user: [user],
  } = useUser();
  const activeNav = useNavValue();
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");

  // useEffect(() => {
  //   activeNav[1]("/dashboard/subscription");
  // }, []);

  return !user?.isAdmin ? <NotFound /> : <CreateSubscription id={id} />;
}
