import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, } from "react-router-dom";
import Button from "../Shared/Btn/Button";

const AdminSubscriptionCard = ({ information, handleDelete }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState();

  /**
   *
   * @param {id} id  single card id
   * 
   * 
   */


  useEffect(() => {
    const idUrl = window.location.href + "/" + information.id

    setUrl(idUrl)
  }, [])

  return (
    <div>
      <div className="card rounded-md text-center bg-[#f1f1f1] shadow-xl ">
        <h1
          className="bg-bgCardHeader text-bgTableHeader text-2xl 
                text-center py-5"
        >
          Subscription Detail
        </h1>
        <div className="card-body">
          <div className="flex mb-5 mt-3">
            <p className="text-left lg:w-48 lg:h-12">
              Name: {information.name}
            </p>
            <p className="text-right lg:w-32">Price: ${information.price}</p>
          </div>
          <p className="mb-4 text-left mr-2 break-all">Package Url: {url}</p>
          <p className="text-left mb-4">Link : </p>
          <div className="flex justify-between mb-3">
            <p className=" text-left">Task number : {information.taskNumber}</p>
            <p className=" text-right">
              Trail period : {information.taskNumber}
            </p>
          </div>
          <p className="text-left mb-4">
            Pack type : {information.hidden ? "Hidden" : "Visible"}
          </p>
          <div className="flex font-bold">
            <p className="text-left">Action name</p>
            <p className="text-right">Total limit</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Follow back : </p>
            <p className="text-right">{information.followBackUserLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Follow users : </p>
            <p className="text-right">{information.followUsersUserLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Share users product : </p>
            <p className="text-right">
              {information.shareUsersProductItemLimit}
            </p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Share back (User limit) : </p>
            <p className="text-right">{information.shareBackUserLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Share back (Item limit) : </p>
            <p className="text-right">{information.shareBackItemLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Share my closet : </p>
            <p className="text-right">{information.shareMyClosetItemLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Follow new users user limit : </p>
            <p className="text-right">{information.followNewUsersUserLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Shares new users item limit : </p>
            <p className="text-right">{information.sharesNewUsersItemLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Offer to likers : </p>
            <p className="text-right">{information.offerToLikersItemLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Clear out offers : </p>
            <p className="text-right">{information.clearOutOffersItemLimit}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
          <div className="flex">
            <p className="text-left">Tag line : </p>
            <p className="text-right">{information.tagLine}</p>
          </div>
          <hr className="my-2 border-bgLabelText" />
        </div>
        <div className="flex justify-between mx-8 mb-5">
          <Button
            onClick={() => handleDelete(information?.id)}
            type="submit"
            children="DELETE"
            customStyle="bg-bgCloseButton lg:w-24 lg:h-10 text-white lg:text-base text-xs p-2"
          />
          <Button
            onClick={() =>
              navigate(`/dashboard/createsub?id=${information?.id}`)
            }
            type="submit"
            children="EDIT"
            customStyle="bg-bgbutton lg:w-16 lg:h-10 text-white lg:text-base text-xs p-2"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminSubscriptionCard;
