import React from "react";
//components
import UpdateProfile from "../../../Components/UpdateProfile/UpdateProfile";

const AdminProfile = () => {
 
  return (
    <div
      className="mt-10 mx-5 grid grid-cols-1 
    xl:grid-cols-2 "
    >
      <UpdateProfile />
    </div>
  );
};

export default AdminProfile;
