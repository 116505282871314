import CryptoJS from "crypto-js";

const FilterEncData = (data, secKey) => {
  return Object.keys(data)
    .filter((key) => data[key] && key.includes(key))
    .reduce((lastValue, currentKey) => {
      return Object.assign(lastValue, {
        [currentKey]: CryptoJS.AES.encrypt(
          data[currentKey].toString(),
          secKey
        ).toString(),
      });
    }, {});
};

export default FilterEncData;
