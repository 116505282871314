import React, { useEffect } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import Button from "../Components/Shared/Btn/Button";
import InputField from "../Components/Shared/inputField/InputField";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useRequest from "../Hooks/useRequest/useRequest";
import useUser from "../Contexts/UserProvider/useUser";
import AlertToster from "../Components/Shared/Toastify/AlertToster";
import CryptoJS from "crypto-js";

const Login = () => {
  const { register, handleSubmit } = useForm();

  // Login Hook
  const login = useRequest();
  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || "/";
  // User <context
  const {
    user: [user, setUser],
  } = useUser();

  useEffect(() => {
    if (from !== "/") {
      if (location.state.from.search) {
        user &&
          navigate(from + "" + location.state.from.search, { replace: true });
      } else {
        user && navigate(from, { replace: true });
      }
    }
  }, [user, navigate]);

  const onSubmit = (data) => {
    data.email = CryptoJS.AES.encrypt(
      data.email,
      process.env.REACT_APP_LOGIN_MAIL_SECRET
    ).toString();
    data.password = CryptoJS.AES.encrypt(
      data.password,
      process.env.REACT_APP_LOGIN_PASS_SECRET
    ).toString();

    login({ uri: "login", method: "POST", data })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          if (data.loginProfile.subscriptionPackage === null) {
            setUser(data.loginProfile);
            navigate("/dashboard/subscription");
          } else {
            setUser(data.loginProfile);
            navigate("/dashboard/taskshedule");
          }
          AlertToster("Success!", "success");
        } else {
          AlertToster(data?.reason, "error");
        }
      })
      .catch((err) => {
        AlertToster("Permission denied!", "error");
      });
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-max space-y-8">
        <div className="mt-20">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <IoIosArrowRoundBack />
              <span className="uppercase">back to home</span>
            </div>
          </Link>

          <h1 className="text-gray-900 text-xl mt-2">
            <span className="font-bold">Login</span> below
          </h1>

          <div className="mt-2">
            <span className="text-bgLabelText">
              Don't have an account?{" "}
              <Link to="/register" className="text-[#a88f90] font-bold">
                Register
              </Link>
            </span>
          </div>
        </div>
        <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label={"Email"}
            type="email"
            register={{ ...register("email"), required: true }}
          />
          <InputField
            label={"Password"}
            type="password"
            register={{ ...register("password"), required: true }}
          />

          <Button
            children={"Login"}
            type="submit"
            className=""
            customStyle={"bg-bgbutton text-bgColor w-36 h-14 mt-8"}
          />
        </form>

        <Link to="/reset">
          <div className="mt-6 text-lg">
            <span>Forgot Password?</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Login;
