import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useNavValue from "../../Contexts/NavProvider/useNavValue";
import RouteObject from "../../Utils/RouteObject/RouteObject";

export const SideNavbar = () => {
  const [activeNav, setActiveNav] = useNavValue();
  const routeObject = RouteObject();
  const navigation = useNavigate();

  useEffect(() => {
    setActiveNav(window.location.pathname);
  }, []);

  const triggerAction = (action, path) => {
    action && action();
    setActiveNav(path);
    navigation(path);
  };

  return (
    <div className="flex min-h-full ">
      <div className=" bg-bgSidebar w-72 ">
        {routeObject?.menuList?.map((data, key) => (
          <div
            key={key}
            onClick={() =>
              triggerAction(data?.action && data?.action, data?.path)
            }
            className={`${
              activeNav === data?.path ? "bg-bgHoverSidebar text-white" : ""
            } text-xl py-4 p-5  duration-500  cursor-pointer`}
          >
            <div className="flex items-center space-x-2">
              <data.icon />
              <span className="tracking-wider text-sm md:text-base">
                {data?.name}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full min-h-screen">
        <Outlet />
      </div>
    </div>
  );
};
