import React, { useEffect, useState } from "react";
import styleprofile from "./styleProfile.module.css"

//components

import Table from "../../../Components/Table/Table";
import Button from "../../../Components/Shared/Btn/Button";
import PoshmarkLink from "../../../Components/PoshmarkLink/PoshmarkLink";
import UpdateProfile from "../../../Components/UpdateProfile/UpdateProfile";
import { useNavigate } from "react-router-dom";
import useUser from "../../../Contexts/UserProvider/useUser";
import useRequest from "../../../Hooks/useRequest/useRequest";
import LocalLoading from "../../../Components/Loading/LocalLoading";

const UserProfile = ({ id }) => {
  const navigate = useNavigate();
  const req = useRequest();
  const [subscription, setSubscription] = useState([]);
  const [Message,setMessage]=useState(false)
  const {
    user: [user, setUser],
    setUserUpdate
  } = useUser();

  useEffect(() => {
    user.subscriptionPackage &&
      req({ uri: `subscription/${user.subscriptionPackage}`, method: "GET" })
        .then((res) => res.json())
        .then(({ data }) => setSubscription(data))
        .catch((err) => console.log(err));
  }, []);

  const onSubscription = () => {
    setMessage(true);
    if (user?.subscriptionPackage) {
           
      req({ uri: "payment/cancel", method: "POST", data: {} })
        .then((res) => res.json())
        .then((data) => {

          console.log(data);
          navigate("subscription")
          setMessage(false);
          
        })
        .catch((err) => {
          console.log("Permission denied!", "error");
         
        })
        .finally(() => setUserUpdate((data) => !data));

    } else {
      navigate("subscription");
    }
  };

  return (
    <div className="relative">

      {/* Start Modal */}
      {Message ? (
        <div className={`${styleprofile.customModal} z-50`}>
          <LocalLoading msg={"Unsubscription processing...."} cStyle={"text-black"}/>
        </div>
      ) : (
        ""
      )}

      {/* END Modal */}

      <div className=" pt-10 mx-5 grid grid-cols-1">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-10">
          <div className="mb-10 ">
            <UpdateProfile />
            <PoshmarkLink id={id} />
          </div>
          <div className="text-center ">
            <Table subscription={subscription} />
            <Button
              type="submit"
              onClick={() => navigate("subscription")}
              children="Upgrade subscription"
              customStyle="bg-bgbutton w-[80%]  h-10 text-white my-5"
            />
            <Button
              type="submit"
              children={
                user?.subscriptionPackage ? "Unsubscribe" : "Subscribe"
              }
              onClick={onSubscription}
              customStyle="bg-bgbutton w-[80%] h-10 text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
