import React from "react";
import useUser from "../../Contexts/UserProvider/useUser";
import NotFound from "../NotFound/NotFound";
import PoshmarkPopup from "./PoshmarkPopup";

export default function PoshWrapper() {
  const {
    user: [user],
  } = useUser();

  return !user?.isAdmin ? <PoshmarkPopup /> : <NotFound />;
}
