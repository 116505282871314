import React, { useState } from "react";
import "./userlist.module.css";
//react icons
import { useEffect } from "react";
import useRequest from "../../../Hooks/useRequest/useRequest";
import { BsPencil } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import UserEditModal from "../../../Components/Shared/Modals/UserEditModal/UserEditModal";
import ConfrmModal from "../../../Components/Shared/Modals/Confim/ConfrmModal";
import AlertToster from "../../../Components/Shared/Toastify/AlertToster";
import customStyle from "./userlist.module.css";

const tableHeader = [
  "user email",
  "Username",
  "Joined",
  "Current Package",
  "Birthday",
  "Actions",
];

const Userlist = () => {
  const [userList, setUserList] = useState([]);
  const [activeModal, setActiveModal] = useState({ active: false, data: {} });
  const req = useRequest();

  useEffect(() => {
    const response = async () => {
      await req({ uri: `users?isAdmin=false`, method: "GET" })
        .then((res) => res.json())
        .then(({ data }) => setUserList(data));
    };
    response();
  }, [activeModal]);

  const onDeleteUser = (id) => {
    try {
      req({ uri: `user/${id}`, method: "DELETE" })
        .then((res) => res.json())
        .then((data) => {
          userList.filter((single) => single.id !== data.id);
          setActiveModal({ active: false, data: {} });
          AlertToster("Deleted user", "success");
        })
        .catch((e) => console.log(e));
    } catch (error) {}
  };

  const getDate = (date) => {
    const d = new Date(date).toLocaleDateString();
    return d;
  };
  return (
    <>
      <div
        style={{ overflow: "auto", overflowY: "hidden" }}
        className="mt-10 mx-5 grid grid-cols-1"
      >
        <div className={`h-[80vh]  overflow-y-scroll ${customStyle.theight}`}>
          <table className={`${customStyle.container} w-full text-center`}>
            <thead>
              <tr className={`${customStyle.trow}`}>
                {tableHeader.map((th) => (
                  <th key={th} className={`${customStyle.thead} capitalize`}>
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userList.map((tdata) => (
                <tr key={tdata.id} className={`${customStyle.trow}`}>
                  <td className={`${customStyle.tdata}`}>{tdata.email}</td>
                  <td className="capitalize">{tdata.name}</td>
                  <td className={`${customStyle.tdata}`}>
                    {tdata.startDate ? getDate(tdata.startDate) : ""}
                  </td>
                  <td className={`${customStyle.tdata}`}>{tdata.packName}</td>
                  <td className={`${customStyle.tdata}`}>{tdata.day}</td>
                  <td>
                    <div className="flex justify-center gap-x-2 text-2xl ">
                      <BsPencil
                        className="hover:text-bgbutton  cursor-pointer"
                        onClick={() =>
                          setActiveModal({ active: true, data: tdata })
                        }
                      />
                      <MdDeleteOutline
                        className="hover:text-bgCloseButton  cursor-pointer"
                        onClick={() => ConfrmModal(onDeleteUser, tdata.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {activeModal.active && (
        <UserEditModal
          activeModal={activeModal}
          setActiveModal={setActiveModal}
        />
      )}
    </>
  );
};

export default Userlist;
