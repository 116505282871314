import React, { useState } from "react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import useUser from "../../Contexts/UserProvider/useUser";
import useRequest from "../../Hooks/useRequest/useRequest";
import LocalLoading from "../Loading/LocalLoading";
import Button from "../Shared/Btn/Button";
import AlertToster from "../Shared/Toastify/AlertToster";
import Table from "../Table/Table";
import styleSub from "./styleSub.module.css";

const UserSubscriptionCard = ({ information }) => {
  const reqPayment = useRequest();
  const [loading, setLoading] = useState(false);
  const {
    user: [user, setUser],
  } = useUser();

  const onPayment = (id) => {
    setLoading(true);
    if (!user?.boughtPackages) {
      reqPayment({
        uri: `payment/createagreement/${id}`,
        method: "POST",
        data: {},
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.forwardLink) {
            window.location.href = res?.forwardLink;
            // setLoading(false);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      AlertToster("Delete you previous subscription", "warning");
      setLoading(false);
    }
  };
  //
  return (
    <>
      {/* Start Modal */}
      {loading ? (
        <div className={`${styleSub.customModal} z-50`}>
          <LocalLoading msg={"Redirect to paypal...."} cStyle={"text-white"} />
        </div>
      ) : (
        ""
      )}

      {/* END Modal */}
      <div className="card text-center  bg-[#f1f1f1] shadow-xl">
        <div className="card-body">
          <h2
            className="text-2xl xl:text-2xl font-semibold capitalize
          "
          >
            {information.name}
          </h2>
          <p className="mb-5">{information.trialPeriod}</p>
          <div className="flex justify-center">
            {" Access to all features"}
            <span className="ml-2 cursor-pointer">
              <label htmlFor="details_id" className="cursor-pointer">
                <BsFillQuestionCircleFill className="mt-1" />
              </label>
              <input type="checkbox" id="details_id" className="modal-toggle" />
              <div className="modal">
                <div className="modal-box  max-w-2xl bg-bgSidebar">
                  <Table subscription={information} />
                  <div className="modal-action">
                    <label
                      htmlFor="details_id"
                      className="bg-bgbutton text-white px-5 py-2 rounded-md"
                    >
                      Close
                    </label>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <hr className="border-bgLabelText my-3" />
          <p>
            Up to {information.shareMyClosetItemLimit} closet shares per task
          </p>
          <hr className="border-bgLabelText my-3" />
          <p>{information.followUsersUserLimit} targeted follows per task</p>
          <hr className="border-bgLabelText my-3" />
          <p>Unlimited offers send per task</p>
          <hr className="border-bgLabelText my-3" />
          <p>{information.trialPeriod} days trial</p>
          <hr className="border-bgLabelText my-3" />
          <p className="text-xl xl:text-2xl font-semibold">
            ${information.price}/month
          </p>
          <hr className="border-bgLabelText my-3" />
          <div className="card-actions justify-center">
            <Button
              onClick={() => onPayment(information.id)}
              children="BUY"
              customStyle="bg-bgbutton w-16 h-10 text-white"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default UserSubscriptionCard;
