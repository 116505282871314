const getTitle = (title, position) => {
  let titleData = title.split("-");

  return titleData[position]
    ?.replace(/\s/g, "")
    ?.replace(/([A-Z])/g, " $1")
    ?.trim();
};

export default getTitle;
