import React from "react";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer } from "react-toastify";
import Routing from "./Utils/Routes/Routing";
import useUser from "./Contexts/UserProvider/useUser";
import Loading from "./Components/Loading/Loading";

function App() {
  const { isLoading } = useUser();
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Routing />
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;
