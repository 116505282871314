import React from "react";

import CryptoJS from "crypto-js";
import { useForm } from "react-hook-form";
import useRequest from "../../../../Hooks/useRequest/useRequest";
import InputField from "../../inputField/InputField";
import AlertToster from "../../Toastify/AlertToster";
import poshLogo from "../../../../Assets/images/poshmark.png";
import Button from "../../Btn/Button";
import { AiFillCloseCircle } from "react-icons/ai";

const PoshmarkPopup = ({ id, setOpen, setUser }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const req = useRequest();
  // OnSubmit Function
  const onSubmit = (data) => {
    data.poshmarkUserName = CryptoJS.AES.encrypt(
      data.poshmarkUserName,
      process.env.REACT_APP_UPDATE_DATA
    ).toString();
    data.poshmarkPassword = CryptoJS.AES.encrypt(
      data.poshmarkPassword,
      process.env.REACT_APP_UPDATE_DATA
    ).toString();
    req({
      uri: `user/update/${id}`,
      method: "PATCH",
      data: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.user) {
          AlertToster("PoshmarkUser Updated", "success");
          setUser(data.user)
          setOpen(false);
        } else {
          AlertToster("Something went error", "error");
        }
      })
      .catch((e) => AlertToster("Bad request", "error"));
  };
  return (
    <>
      <div
        className={`bg-[#ffffffc7] w-full h-full 
    absolute top-0 left-0
  flex justify-center  `}
      >
        <div className="w-full  max-w-md space-y-8 ">
          <div className="relative bg-bgSidebar rounded-xl shadow-xl px-10 pb-10">
            <div
              onClick={() => setOpen(false)}
              className="absolute right-1 top-1 cursor-pointer hover:text-gray-500"
            >
              <AiFillCloseCircle className="h-7 w-7" />
            </div>
            <div className="mt-20 text-center">
              <div className="flex items-center space-x-2">
                <img src={poshLogo} alt="" />
              </div>
              <span className="text-bgHoverSidebar">
                Link Happy Thumbs to your Poshmark account.
              </span>
            </div>
            <form
              className="mt-8 space-y-6 text-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputField
                label={"Poshmark Username"}
                register={{ ...register("poshmarkUserName"), required: true }}
              />
              <InputField
                type="password"
                label={"Poshmark Password"}
                register={{ ...register("poshmarkPassword"), required: true }}
              />
              <Button
                children={"Log in"}
                type="submit"
                customStyle={"bg-bgbutton w-44 h-10 text-bgColor"}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoshmarkPopup;
