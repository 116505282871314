import React from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import useRequest from "../../Hooks/useRequest/useRequest";
import Button from "../Shared/Btn/Button";
import InputField from "../Shared/inputField/InputField";
import AlertToster from "../Shared/Toastify/AlertToster";

const SentOTP = ({ setOTPInfo, OTPinfo }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const forgetReq = useRequest();

  const onSubmit = (data) => {
    const newData = {
      token: OTPinfo.token,
      otp: data.otp,
    };
    console.log(newData);
    forgetReq({ uri: "verify", method: "POST", data: newData })
      .then((res) => res.json())
      .then(({ response }) => {
        if (response.setpass) {
          setOTPInfo({ ...OTPinfo, isValid: true, otp: data.otp });
          AlertToster("Success!", "success");
        } else {
          AlertToster("Something wrong!", "error");
        }
      });
  };
  return (
    <div className="py-36 px-6  w-full">
      <div className="w-[31.25rem] m-auto">
        <Link to="/login" className="flex justify-between mb-10">
          {" "}
          <div className="flex items-center space-x-2">
            <IoIosArrowRoundBack />
            <span className="uppercase">back to Login</span>
          </div>
          <span className="capitalize text-bgCardHeader">Reset password</span>
        </Link>
        <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label={"OTP"}
            register={{ ...register("otp"), required: true }}
          />
          <Button
            children={"Submit OTP"}
            type="submit"
            customStyle={"bg-bgbutton text-bgColor w-52 h-14 mt-8"}
          />
        </form>
      </div>
    </div>
  );
};

export default SentOTP;
