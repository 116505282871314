import React, { useState } from "react";
import { createContext } from "react";

export const NavContext = createContext();

function NavProvider({ children }) {
  const activeNav = useState(null);

  return (
    <NavContext.Provider value={activeNav}>{children}</NavContext.Provider>
  );
}

export default NavProvider;
