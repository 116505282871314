export default function useRequest() {
  return function ({ uri, method, data }) {
    return fetch(`${process.env.REACT_APP_API_URL}/${uri}`, {
      method,
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
  };
}
