import React from "react";

const LocalLoading = ({ msg, cStyle }) => {
  return (
    <div>
      <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
        <div className="border-t-transparent border-solid animate-spin  rounded-full border-bgCardHeader border-8 h-40 w-40"></div>
      </div>
      <h1 className={`text-2xl mt-52 ${cStyle}`}>{msg}....</h1>
    </div>
  );
};

export default LocalLoading;
