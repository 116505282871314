import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "./customDesing.css";

const ConfrmModal = (onDeleteUser, id) => {
  confirmAlert({
    title: "Confirm to submit",
    message: "Are you sure to do this.",
    overlayClassName: "customDesing",

    buttons: [
      {
        label: "Yes",
        onClick: () => onDeleteUser(id),
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
  });
};

export default ConfrmModal;
