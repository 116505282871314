import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useUser from "../../Contexts/UserProvider/useUser";
import useRequest from "../../Hooks/useRequest/useRequest";
import Button from "../Shared/Btn/Button";
import InputField from "../Shared/inputField/InputField";
import AlertToster from "../Shared/Toastify/AlertToster";
import CryptoJS from "crypto-js";
import FilterEncData from "../../Utils/EncriptedData/FilterEncData";

const UpdateProfile = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const {
    user: [user, setUser],
    isLoading,
  } = useUser();
  const req = useRequest();

  /**
   *
   * @param {data} return form data < Object type/>
   * @returns
   */
  useEffect(() => {
    setValue("name", user?.name);
    setValue("email", user?.email);
    setValue("month", user?.month);
    setValue("day", user?.day);
  }, [user]);

  const onSubmit = (data) => {
    setLoading(true);
    //for encrypting the data by FilterEncData filter
    let cData = FilterEncData(data, process.env.REACT_APP_UPDATE_DATA);

    req({
      uri: `user/update/${user?.id}`,
      method: "PATCH",
      data: cData,
    })
      .then((res) => res.json())
      .then(({ user }) => {
        if (user) {
          AlertToster("Updated User", "success");
          setUser(user);
        } else {
          AlertToster("Something wrong!", "error");
        }
        setLoading(false);
      });
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Name"
          type="text"
          customStyle="text-black capitalize"
          register={{ ...register("name"), required: true }}
        />
        <InputField
          label="Email"
          type="email"
          customStyle="text-black capit"
          register={{ ...register("email"), required: true }}
        />
        <InputField
          label="Password"
          type="password"
          customStyle="text-black"
          register={{
            ...register("password", { minLength: 7 }),
            required: false,
          }}
        />
        <p className="text-[#9e9e9e]">Birthday</p>
        <div className="flex flex-col md:flex-row gap-x-4 mb-8">
          <InputField
            label="Month"
            type="text"
            customStyle="text-black"
            register={{ ...register("month", { max: 12 }), required: false }}
          />
          <InputField
            label="Day"
            type="text"
            customStyle="text-black"
            register={{ ...register("day", { max: 31 }), required: false }}
          />
        </div>
        {loading ? (
          <Button
            type="button"
            children="waiting...."
            customStyle="bg-bgbutton w-full h-10 text-white"
          />
        ) : (
          <Button
            type="submit"
            children="Update Profile"
            customStyle="bg-bgbutton w-full h-10 text-white"
          />
        )}
      </form>
    </div>
  );
};

export default UpdateProfile;
