import React, { useEffect, useState } from "react";
import useUser from "../../Contexts/UserProvider/useUser";
import useRequest from "../../Hooks/useRequest/useRequest";
import "./Table.css";

const Table = ({ subscription }) => {
  return (
    <table className=" text-center bg-[#e6e6e6] w-full  table-area">
      <thead>
        <tr className="bg-gray-300">
          <th className="table-head" colSpan="3">
            Active sub scription package:
          </th>
        </tr>
        <tr className="">
          <th className="table-head">Tasks</th>
          <th className="table-head">Each task block include</th>
          <th className="table-head">Package includes (per task)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="table-data">Share my closet</td>
          <td className="table-data">Shares your closet</td>
          <td className="table-data">
            {subscription?.shareUsersProductItemLimit || 0}
          </td>
        </tr>
        <tr>
          <td className="table-data">Share other closets</td>
          <td className="table-data">Shares closets from feed</td>
          <td className="table-data">
            {subscription?.shareBackUserLimit || 0}
          </td>
        </tr>
        <tr>
          <td className="table-data" rowSpan="2">
            Follow
          </td>
          <td className="table-data">Follow other users</td>
          <td className="table-data">
            {subscription?.followBackUserLimit || 0}
          </td>
        </tr>
        <tr>
          <td className="table-data">Follow back users that follow you</td>
          <td className="table-data">
            {subscription?.followUsersUserLimit || 0}
          </td>
        </tr>
        <tr>
          <td className="table-data" rowSpan={2}>
            New posher love
          </td>
          <td className="table-data">Follow new users</td>
          <td> {subscription?.followNewUsersUserLimit || 0}</td>
        </tr>
        <tr>
          <td className="table-data">Shares new users items</td>
          <td className="table-data">
            {subscription?.sharesNewUsersItemLimit || 0}
          </td>
        </tr>
        <tr>
          <td className="table-data">Offer to likers</td>
          <td className="table-data">Sends offers to recent likers</td>
          <td className="table-data">
            {/* {subscription?.offerToLikersItemLimit || 0} */}
            Unlimited
          </td>
        </tr>
        <tr>
          <td className="table-data">Clear out closet</td>
          <td className="table-data">
            Sends offers to all items in your closet
          </td>
          <td className="table-data">
            {/* {subscription?.clearOutOffersItemLimit || 0} */}
            Unlimited

          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;