import React from "react";
import { useRoutes } from "react-router-dom";
import DashBoard from "../../Layouts/DashBoard";
import Home from "../../Pages/Home/Home";
import Login from "../../Pages/Login";
import NotFound from "../../Pages/NotFound/NotFound";
import Register from "../../Pages/Register";
import AuthCheck from "../Middleware/AuthCheck";
import ProfileWrapper from "../../Pages/UserProfile/ProfileWrapper";
import TaskWrapper from "../../Pages/TaskShedules/TaskWrapper";
import SubWrapper from "../../Pages/Subscriptions/SubWrapper";
import CreateWrapper from "../../Pages/CreateSubscription/CreateWrapper";
import ListWrapper from "../../Pages/Userlist/ListWrapper";
import ResetWrapper from "../../Pages/ForgetPassword/ResetWrapper";
import PoshWrapper from "../../Pages/PoshmarkPopup/PoshWrapper";

const Routing = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/reset",
      element: <ResetWrapper />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/dashboard",
      element: (
        <AuthCheck>
          <DashBoard />
        </AuthCheck>
      ),
      children: [
        {
          index: true,
          element: <ProfileWrapper />,
        },
        {
          path: "taskshedule",
          element: <TaskWrapper />,
        },
        {
          path: "subscription",
          element: <SubWrapper />,
        },
        {
          path: "createsub",
          element: <CreateWrapper />,
        },
        {
          path: "userlist",
          element: <ListWrapper />,
        },
        {
          path: "poshmark",
          element: <PoshWrapper />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return routes;
};

export default Routing;
