import React from "react";
import Header from "../Components/Shared/Header/Header";
import { SideNavbar } from "../Components/SideNavbar/SideNavbar";
import NavProvider from "../Contexts/NavProvider/NavProvider";

const DashBoard = () => {
  return (
    <div className="w-full">
      <NavProvider>
        <Header />
        <SideNavbar />
      </NavProvider>
    </div>
  );
};

export default DashBoard;
