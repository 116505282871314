import React from "react";
import { Link } from "react-router-dom";
import Calendar from "../../../Components/TaskShedules/Calendar";
import useNavValue from "../../../Contexts/NavProvider/useNavValue";

const TaskShedules = ({ user }) => {
  const activeNav = useNavValue();

  return (
    <>
      {user?.boughtPackages ? (
        <div className="grid grid-cols-1 pt-10 mx-5">
          <h1 className="mb-5 text-2xl font-semibold text-bgCardHeader">
            Dashbord
          </h1>
          <div className="shadow-xl ">
            <Calendar />
          </div>
        </div>
      ) : (
        <div className="flex  items-center justify-center h-screen text-gray-700">
          <h1 className="text-center font-bold tracking-wider text-lg">
            Please subscribe to set your weekly schedule <br />
            <Link
              onClick={function () {
                activeNav[1]("/dashboard/subscription");
              }}
              to="/dashboard/subscription"
              className=" px-2   text-sm "
            >
              click here
            </Link>
          </h1>
        </div>
      )}
    </>
  );
};

export default TaskShedules;
