import React, { useState } from "react";
import TaskModal from "../Shared/Modals/TaskSheduleModal/TaskModal";
import TdComp from "./Tbl_Component/TdComp";
import { useEffect } from "react";
import useRequest from "../../Hooks/useRequest/useRequest";
import getTitle from "../../Utils/FilterString/getTitle";

const days = {
  6: "saturday",
  0: "sunday",
  1: "monday",
  2: "thuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
};
const times = {
  26: "02:00AM",
  28: "04:00AM",
  6: "06:00AM",
  8: "08:00AM",
  10: "10:00AM",
  12: "12:00PM",
  14: "02:00PM",
  16: "04:00PM",
  18: "06:00PM",
  20: "08:00PM",
  22: "10:00PM",
  24: "12:01AM",
};

const Calendar = () => {
  const taskRes = useRequest();
  const [tasks, setTasks] = useState([]);
  const [showModal, setShowMadal] = useState({
    show: false,
    currentValue: {
      day: null,
      time: null,
      title: null,
      dayName: null,
      timeValue: null,
      id: null,
    },
  });
  const parsed = {};
  // Load all task and set value of Tasks statge
  useEffect(() => {
    taskRes({ uri: "task", method: "GET" })
      .then((res) => res.json())
      .then(({ data }) => setTasks(data))
      .catch((err) => console.log(err));
  }, [showModal.show]);

  // Data set is converted according to the table
  if (tasks.length > 0) {
    tasks.forEach((i) => {
      const hour = i?.hour;
      const day = i?.days;
      if (!parsed[hour]) {
        const jsonObj = {};
        jsonObj[day] = i?.actionName + "-" + i?.id;

        parsed[hour] = jsonObj;
        return;
      }
      parsed[hour][day] = i?.actionName + "-" + i?.id;
    });
  }

  // get value of day,time and title of tasks
  const handleEvent = (e, day, time, title) => {
    e.preventDefault();
    if (title) {
      setShowMadal({
        show: !showModal.show,
        currentValue: {
          day: day,
          time: time,
          title: title,
          dayName: days[day],
          timeValue: times[time],
          id: getTitle(title, 1),
        },
      });
    } else {
      setShowMadal({
        show: !showModal.show,
        currentValue: { day: day, time: time, title: null },
      });
    }
  };

  // Handle Time method 26 and 28 times converted
  const getTime = (time) => {
    if (parseInt(time) === 26) {
      return 2 + "";
    } else if (parseInt(time) === 28) {
      return 4 + "";
    } else {
      return time;
    }
  };
  return (
    <>
      <div className="overflow-x-auto ">
        <table className="table-calendar w-full">
          <thead className="">
            <tr className="bg-bgTableHeader  ">
              <th className="border-2 border-white  p-3 "></th>
              {Object.keys(days).map((dayKey, key) => (
                <th className="border-2 border-white capitalize p-3" key={key}>
                  {days[dayKey]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(times).map((time, i) => (
              <tr key={i}>
                <td
                  key={i}
                  className={`border-2 cursor-pointer text-center p-3 bg-bgTableHeader border-gray-100 font-[600]`}
                >
                  <span className="capitalize text-gray-700">
                    {times[time]}
                  </span>
                </td>
                <TdComp
                  time={parseInt(getTime(time))}
                  parseData={parsed}
                  days={days}
                  onClick={handleEvent}
                  k={i}
                />
              </tr>
            ))}
          </tbody>
        </table>
        {showModal.show && (
          <TaskModal showModal={showModal} setShowMadal={setShowMadal} />
        )}
      </div>
    </>
  );
};

export default Calendar;
