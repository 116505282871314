import React from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Shared/Btn/Button";
import InputField from "../Shared/inputField/InputField";
import AlertToster from "../Shared/Toastify/AlertToster";
import useRequest from "../../Hooks/useRequest/useRequest";
import CryptoJS from "crypto-js";

const NewPassowrd = ({ setOTPInfo, OTPinfo }) => {
  // const navigator = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const req = useRequest();

  const onSubmit = (data) => {
    if (!data.password.localeCompare(data.repassword)) {
      const newData = {
        password: CryptoJS.AES.encrypt(
          data.password,
          process.env.REACT_APP_RESET_PASS_SECRET
        ).toString(),
        otp: CryptoJS.AES.encrypt(
          OTPinfo.otp,
          process.env.REACT_APP_RESET_PASS_SECRET
        ).toString(),
        token: OTPinfo.token,
      };
      req({ uri: "verify", method: "POST", data: newData })
        .then((res) => res.json())
        .then((data) => {
          data.status === 200 &&
            setOTPInfo({
              token: null,
              otp: null,
              status: false,
              isValid: false,
            });
          AlertToster(data.response.message, "error");
        });
    } else {
      AlertToster("something went wrong", "error");
    }
  };
  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 ">
        <div className="mt-20">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <IoIosArrowRoundBack />
              <span className="uppercase">back to home</span>
            </div>
          </Link>
        </div>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type="password"
            label={"New Password"}
            register={{
              ...register("password", { minLength: 7 }),
              required: true,
            }}
          />
          <InputField
            type="password"
            label={"Re-password"}
            register={{
              ...register("repassword", { minLength: 7 }),
              required: true,
            }}
          />

          <Button
            children={"Reset"}
            type="submit"
            customStyle={"bg-bgbutton text-bgColor w-36 h-14 mt-8"}
          />
        </form>
      </div>
    </div>
  );
};

export default NewPassowrd;
