import React from "react";
import useUser from "../../Contexts/UserProvider/useUser";
import NotFound from "../NotFound/NotFound";
import TaskShedules from "./Tasks/TaskShedules";

export default function TaskWrapper() {
  const {
    user: [user],
  } = useUser();

  return !user?.isAdmin ? <TaskShedules user={user} /> : <NotFound />;
}
