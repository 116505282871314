import { useEffect } from "react";
import { createContext, useState } from "react";
import useRequest from "../../Hooks/useRequest/useRequest";

export const User = createContext();

function UserProvider({ children }) {
  const user = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userUpdate, setUserUpdate] = useState(false);
  const request = useRequest();

  function fetchUser() {
    setIsLoading(true);
    request({ uri: "userprofile", method: "GET" })
      .then((res) => res.json())
      .then(({ userProfile }) => {
        user[1](userProfile);
      })
      .catch(() => {
        user[1](null);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [userUpdate]);

  return (
    <User.Provider value={{ user, isLoading, setIsLoading, setUserUpdate }}>
      {children}
    </User.Provider>
  );
}

export default UserProvider;
