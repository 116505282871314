import Dropdown from "../../../Dropdown/Dropdown";
import InputField from "../../../inputField/InputField";

const offerToLike = (
  ShippindPaidOption,
  setShippingPaid,
  register,
  shippingPaid
) => {
  return (
    <>
      <div className="flex items-center justify-between space-x-2">
        <InputField
          type="number"
          label={"Number"}
          value="10"
          register={{
            ...register("discountPercentage"),
            required: true,
            minLength: 10,
          }}
        />
        <span className=" text-bgLabelText">%off</span>
      </div>
      <Dropdown
        options={ShippindPaidOption}
        label="Free"
        onChange={setShippingPaid}
      />
      {shippingPaid?.toLowerCase() !== "free" ? (
        <InputField
          type="number"
          label={"Minimum price to offer free shipping"}
          register={{ ...register("minimumShippingPaidValue"), required: true }}
        />
      ) : (
        ""
      )}
    </>
  );
};
const selectedItem = {
  "Offer To Likers": (
    ShippindPaidOption,
    setShippingPaid,
    register,
    shippingPaid
  ) =>
    // offerToLike is a components
    offerToLike(ShippindPaidOption, setShippingPaid, register, shippingPaid),
  Follow: () => <></>,
  "Share My Closet": (ShippindPaidOption, setShippingPaid, register) => (
    <>
      <div className="flex flex-col items-start space-y-2 text-bgbutton">
        <label className="cursor-pointer flex text-base font-semibold  gap-2">
          <input
            type={"radio"}
            name="topToBottom"
            value={true}
            className="cursor-pointer"
            {...register("topToBottom")}
          />
          Top to bottom
        </label>

        <label className="cursor-pointer flex text-base font-semibold  gap-2">
          <input
            type={"radio"}
            name="topToBottom"
            defaultChecked
            value={false}
            className="cursor-pointer"
            {...register("topToBottom")}
          />
          Bottom to Top
        </label>
      </div>
    </>
  ),
  "Share Other Closets": () => <></>,
  "New Posher Love": () => <></>,
  "Clear Out Closet": (
    ShippindPaidOption,
    setShippingPaid,
    register,
    shippingPaid
  ) => offerToLike(ShippindPaidOption, setShippingPaid, register, shippingPaid),
};

export default selectedItem;
