import React, { useEffect, useState } from "react";
import UserSubscriptionCard from "../../../Components/UserSubscription/UserSubscriptionCard";
import useRequest from "../../../Hooks/useRequest/useRequest";

const UserSubscriptionList = () => {
  const [informations, setInformations] = useState(null);
  const reqSub = useRequest();

  useEffect(() => {
    reqSub({ uri: "subscriptions?hidden=false", method: "GET" })
      .then((res) => res.json())
      .then((res) => setInformations(res?.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      className="relative grid grid-cols-1 md:grid-cols-2 
      xl:grid-cols-3  gap-6 pt-10 pb-10 px-5"
    >
      {informations &&
        informations.map((information) => (
          <UserSubscriptionCard
            key={information.id}
            information={information}
          ></UserSubscriptionCard>
        ))}
    </div>
  );
};

export default UserSubscriptionList;
