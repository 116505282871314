import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Assets/images/logo.png";
import useUser from "../../../Contexts/UserProvider/useUser";

const Header = () => {
  const {
    user: [user, setUser],
  } = useUser();

  return (
    <div className="bg-[#161616] min-w-full ">
      <div className="flex justify-between py-5 mx-5">
        <Link to="/dashboard">
          <img src={logo} alt="" className="w-44  md:w-64 h-10" />
        </Link>
        <p className="text-xl mt-2 text-white capitalize">
          {user?.name ? user?.name : user?.email}
        </p>
      </div>
    </div>
  );
};

export default Header;
