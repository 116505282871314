import React from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import useRequest from "../../Hooks/useRequest/useRequest";
import Button from "../Shared/Btn/Button";
import InputField from "../Shared/inputField/InputField";
import AlertToster from "../Shared/Toastify/AlertToster";

const RequestbyEmail = ({ setOTPInfo, OTPinfo }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const req = useRequest();
  const onSubmit = (data) => {
    req({ uri: "forgot", method: "POST", data: data })
      .then((res) => res.json())
      .then((response) => {
        response.status === 504 && AlertToster(response.reason, "error");
        if (response.response.status) {
          AlertToster("Sent to mail", "success");
          setOTPInfo({
            ...OTPinfo,
            token: response.token,
            otp: response.otp,
            status: response.status,
          });
        }
      });
  };
  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 ">
        <div className="mt-20">
          <Link to="/">
            <div className="flex items-center space-x-2">
              <IoIosArrowRoundBack />
              <span className="uppercase">back to home</span>
            </div>
          </Link>
        </div>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label={"Email"}
            register={{ ...register("email"), required: true }}
          />
          <p className="text-sm">
            Once you enter your registered email address, you will receive an
            email{" "}
            <span className="font-bold">
              (check your spam folder if you don't see it!)
            </span>{" "}
            with a one-time code to enter on the line above to reset your
            account password.
          </p>
          <Button
            children={"submit"}
            type="submit"
            customStyle={"bg-bgbutton text-bgColor w-36 h-14 mt-8"}
          />
        </form>
      </div>
    </div>
  );
};

export default RequestbyEmail;
