import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Shared/Btn/Button";

const Home = () => {
  return (
    <div className="h-screen w-full flex items-center">
      <div className="border w-[34.375rem] sm:h-80 mx-auto shadow-lg p-7">
        <h1 className="text-4xl text-center mt-6">
          Welcome to <br />{" "}
          <span className="font-bold text-[2.5rem]">HAPPY THUMBS</span>
        </h1>

        <div className="flex justify-around my-11">
          <Link to="/register">
            <Button
              customStyle='bg-bgbutton text-bgColor w-[140px] h-[54px] type="button'
              children={"register"}
            />
          </Link>
          <Link to="/login">
            <Button
              customStyle='bg-bgColor w-[140px] h-[54px] type="button'
              children={"log in"}
            />
          </Link>
        </div>

        <div className="text-center text-lg">
          <Link to="/reset">
            {" "}
            <p className="cursor-pointer">Forgot Password?</p>{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
