import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useUser from "../../Contexts/UserProvider/useUser";

export default function AuthCheck({ children }) {
  const location = useLocation();
  const {
    user: [thisUser],
  } = useUser();

  return thisUser ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
