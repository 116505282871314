import React from "react";
import getFilter from "../../../../../Utils/FilterString/getTitle";
import { IoIosCloseCircle } from "react-icons/io";

const TaskInfo = ({ title, dayName, time, onClose = () => {} }) => {
  return (
    <div className="bg-bgTableHeader p-2 mb-10 shadow-xl rounded-md text-bgHoverSidebar ">
      <IoIosCloseCircle
        onClick={onClose}
        className="absolute top-2 right-2 text-3xl hover:text-gray-600 cursor-pointer"
      />
      <h1 className="text-lg font-bold text-center capitalize">Task Details</h1>
      <p className="ml-5">
        Task name:{" "}
        <span className="font-bold capitalize">{getFilter(title, 0)}</span>
      </p>
      <p className="ml-5">
        Task time:{" "}
        <span className="font-bold capitalize">
          {dayName} {time}
        </span>
      </p>
    </div>
  );
};

export default TaskInfo;
