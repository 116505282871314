import React, { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import NewPassowrd from "../../Components/ForgotPass/NewPassowrd";
import RequestbyEmail from "../../Components/ForgotPass/RequestByEmail";
import SentOTP from "../../Components/ForgotPass/SentOTP";

// ?otp=1245&token=fbhfdsgbaafhbgjhadfbghjadfgb
const ResetWrapper = () => {

  const [OTPinfo, setOTPInfo] = useState({
    token: null,
    otp: null,
    status: false,
    isValid: false,
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("otp") && searchParams.get("token")) {
      setOTPInfo({
        ...OTPinfo,
        otp: searchParams.get("otp"),
        token: searchParams.get("token"),
        isValid: true,
      });
    }
  }, []);
  return (
    <>
      {!OTPinfo.isValid ? (
        !OTPinfo.status ? (
          <RequestbyEmail setOTPInfo={setOTPInfo} OTPinfo={OTPinfo} />
        ) : (
          <SentOTP setOTPInfo={setOTPInfo} OTPinfo={OTPinfo} />
        )
      ) : (
        <NewPassowrd setOTPInfo={setOTPInfo} OTPinfo={OTPinfo} />
      )}
    </>
  );
};

export default ResetWrapper;
