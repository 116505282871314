import React, { useEffect, useState } from "react";
import useRequest from "../../Hooks/useRequest/useRequest";
import ConfrmModal from "../Shared/Modals/Confim/ConfrmModal";
import AdminSubscriptionCard from "./AdminSubscriptionCard";

const AdminSubscriptionCards = () => {
  const [informations, setInformations] = useState([]);

  const reqSub = useRequest();
  useEffect(() => {
    const getData = () => {
      reqSub({ uri: "subscriptions", method: "GET" })
        .then((res) => res.json())
        .then((res) => setInformations(res?.data))
        .catch((err) => console.log(err));
    };
    getData();
  }, []);

  const handleDelete = (id) => {
    reqSub({ uri: `subscription/${id}`, method: "DELETE" })
      .then((res) => res.json())
      .then(({ data }) => {
        let remaining = informations.filter((single) => single.id !== id);
        setInformations(remaining);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div
      className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3
             gap-6"
    >
      {informations &&
        informations.map((information) => (
          <AdminSubscriptionCard
            key={information.id}
            information={information}
            handleDelete={() => ConfrmModal(handleDelete, information.id)}
          ></AdminSubscriptionCard>
        ))}
    </div>
  );
};

export default AdminSubscriptionCards;
