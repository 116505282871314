import React from "react";
import { useForm } from "react-hook-form";
import logo from "../../Assets/images/poshmark.png";
import Button from "../../Components/Shared/Btn/Button";
import InputField from "../../Components/Shared/inputField/InputField";
import CryptoJS from "crypto-js";
import AlertToster from "../../Components/Shared/Toastify/AlertToster";
import useRequest from "../../Hooks/useRequest/useRequest";

const PoshmarkPopup = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const req = useRequest();
  // OnSubmit Function
  const onSubmit = (data) => {
    // data.poshmarkUserName = CryptoJS.AES.encrypt(
    //   data.poshmarkUserName,
    //   process.env.REACT_APP_UPDATE_DATA
    // ).toString();
    // data.poshmarkPassword = CryptoJS.AES.encrypt(
    //   data.poshmarkPassword,
    //   process.env.REACT_APP_UPDATE_DATA
    // ).toString();
    // req({
    //   uri: `user/update/${id}`,
    //   method: "PATCH",
    //   data: data,
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //     if (data.user) {
    //       AlertToster("User Updated", "success");
    //       setActiveModal({ active: false, data: {} });
    //     } else {
    //       AlertToster("Something went error", "error");
    //     }
    //   })
    //   .catch((e) => AlertToster("Bad request", "error"));
  };
  return (
    <div className="flex min-h-full justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 ">
        <div className="mt-20 text-center">
          <div className="flex items-center space-x-2">
            <img src={logo} alt="" />
          </div>
          <span className="text-bgHoverSidebar">
            Link Happy Thumbs to your Poshmark account.
          </span>
        </div>
        <form
          className="mt-8 space-y-6 text-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputField
            label={"Poshmark Username"}
            register={{ ...register("poshmarkUserName"), required: true }}
          />
          <InputField
            label={"Poshmark Password"}
            register={{ ...register("poshmarkPassword"), required: true }}
          />
          <Button
            children={"Log in"}
            type="submit"
            customStyle={"bg-bgbutton w-44 h-10 text-bgColor"}
          />
        </form>
      </div>
    </div>
  );
};

export default PoshmarkPopup;
