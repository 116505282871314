import React, { useEffect } from "react";
import useNavValue from "../../Contexts/NavProvider/useNavValue";
import useUser from "../../Contexts/UserProvider/useUser";
// import NotFound from "../NotFound/NotFound";
import AdminSubscriptionList from "./SubLists/AdminSubscriptionList";
import UserSubscriptionList from "./SubLists/UserSubscriptionList";

export default function SubWrapper() {
  const {
    user: [user],
  } = useUser();
  const activeNav = useNavValue();

  useEffect(() => {
    user && activeNav[1]("/dashboard/subscription");
  }, []);

  return !user?.isAdmin ? (
    // !user?.boughtPackages ? (
    //   <UserSubscriptionList />
    // ) : (
    //   <NotFound />
    // )
    <UserSubscriptionList />
  ) : (
    <AdminSubscriptionList />
  );
}
