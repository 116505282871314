import React from "react";
import getFilter from "../../../Utils/FilterString/getTitle";
import "./tbl_comp.css";

// filed of table componetns
const TdComp = ({ time, parseData, days, onClick = () => {} }, k) => {
  function checkValue(time) {
    return parseData[time];
  }

  /**getFilter method split title and id */
  return (
    <>
      {checkValue(time)
        ? Object.keys(parseData).map((hour) => {
            if (parseInt(hour) === time) {
              return Object.keys(days).map((dayKey, i) => (
                <td
                  key={i}
                  onClick={(e) =>
                    onClick(e, dayKey, time, parseData[hour][dayKey])
                  }
                  className={`border-2 cursor-pointer text-center
                   p-3 
                    ${
                      "" +
                      parseData[hour][dayKey]?.toLowerCase()?.replace(/\s/g, "")
                    }
                  `}
                >
                  <span className="capitalize">
                    {typeof parseData[hour][dayKey] !== "undefined"
                      ? getFilter(parseData[hour][dayKey], 0)
                      : " "}
                  </span>
                </td>
              ));
            }
          })
        : Object.keys(days).map((dayKey, k) => (
            <td
              key={k}
              onClick={(e) => onClick(e, dayKey, time)}
              className={`border-2 cursor-pointer text-center p-3 `}
            >
              <span className="capitalize"> </span>
            </td>
          ))}
    </>
  );
};

export default TdComp;
