import React, { useEffect } from "react";
import useNavValue from "../../Contexts/NavProvider/useNavValue";
import useUser from "../../Contexts/UserProvider/useUser";
import AdminProfile from "./Profile/AdminProfile";
import UserProfile from "./Profile/UserProfile";

export default function ProfileWrapper() {
  const {
    user: [user],
  } = useUser();
  const activeNav = useNavValue();

  useEffect(() => {
    activeNav[1]("/dashboard");
  });

  return !user?.isAdmin ? <UserProfile id={user?.id} /> : <AdminProfile />;
}
